<template>
  <v-card class="elevation-0" color="white">
    <vue-croppie
      ref="croppieRef"
      :enableExif="false"
      :enforceBoundary="true"
      :enableResize="false"
      :enableOrientation="false"
      :mouseWheelZoom="true"
      :showZoomer="false"
      :boundary="{ width: 350, height: 500 }"
      :viewport="{ width: 200, height: 200, type: 'circle' }"
    >
    </vue-croppie>
    <v-btn
      absolute
      bottom
      left
      style="z-index: 1000"
      dark
      color="grey darken-2"
      @click="cancelar"
    >
      CANCELAR
    </v-btn>
    <v-btn
      dark
      color="green"
      @click="cropper"
      absolute
      bottom
      right
      style="z-index: 1000"
    >
      <v-spacer></v-spacer>
      RECORTAR
      <v-spacer></v-spacer>
    </v-btn>
  </v-card>
</template>

<script>
window.EXIF = require("exif-js");

export default {
  props: {
    src: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showCropper: false
    };
  },
  watch: {
    src(val) {
      if (val) {
        this.$refs.croppieRef.bind({
          url: val
        });
        this.$nextTick(() => {
          this.$refs.croppieRef.setZoom(0.1);
        });
      }
    }
  },
  mounted() {
    if (this.src) {
      this.$refs.croppieRef.bind({
        url: this.src
      });
      this.$nextTick(() => {
        this.$refs.croppieRef.setZoom(0.1);
      });
    }
  },
  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    cropper() {
      let options = {
        quality: 0.1,
        format: "png",
        circle: true
      };
      this.$refs.croppieRef.result(options, output => {
        this.$emit("cropped", output);
      });
    }
  }
};
</script>

<style scoped>
img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
}
</style>
